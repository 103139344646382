<template>
  <div class="sld_chat_right">
    <div class="right_tab">
      <div :class="{ tab1: true, on: 1 == tabIndex }" @click="changeTab(1)">{{ L['用户订单'] }}</div>
      <div :class="{ tab2: true, on: 2 == tabIndex }" @click="changeTab(2)">{{ L['用户足迹'] }}</div>
      <div :class="{ tab3: true, on: 3 == tabIndex }" @click="changeTab(3)" v-if="getIdentity() == 'seller'">{{
        L['店铺商品']
      }}</div>
      <div :class="{ tab4: true, on: 4 == tabIndex }" v-if="storeInfo.isOwnStore === 2" @click="changeTab(4)">{{ L['自营商品'] }}</div>
    </div>

    <div class="right_list_con" v-if="1 == tabIndex" :style="'height:' + (clientHeight - 142) + 'px'">
      <el-scrollbar ref="orderbar">
        <!-- 订单列表start -->
        <div class="orderOwn" v-infinite-scroll="load">
          <div class="order_item" v-for="(item, orderIndex) in orderList.list" :key="orderIndex">
            <div class="order_title">
              <div class="order_title_info">
                <p>{{ L['订单号'] }}：{{ item.orderSn }}</p>
                <p>{{ item.createTime }}</p>
              </div>
              <div class="order_state">{{ item.orderStateValue }}</div>
            </div>
            <div>
              <div class="goods_model order_type" :class="{ sld_hide: item.isFold, sld_show: !item.isFold }"
                v-for="(goodsItem, goodsIndex) in item.orderProductList" :key="goodsIndex"
                @click="toDetail(item, 'order')">
                <div class="goods_m_img">
                  <img :src="goodsItem.productImage" alt="">
                </div>
                <div class="goods_info">
                  <div class="goods_info_title">
                    {{ goodsItem.goodsName }}
                  </div>
                  <div class="goods_info_bottom">
                    <span>{{ L['￥'] }}{{ goodsItem.productShowPrice }}</span>
                    <span
                      @click.stop="sendLink({ orderSn: item.orderSn, createTime: item.createTime, orderStateValue: item.orderStateValue, goodsItem }, 'order')">{{
                        L['发送链接']
                      }}
                      ></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="openMore" @click="openMore(item)" v-if="item.orderProductList.length > 3">
              <span>{{ item.isFold ? '展开全部' : '收起全部' }}</span>
              <i :class="item.isFold ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
            </div>
          </div>
          <loadingState v-if="loadState.orderState == 'first_loading' || orderList.list.length > 0"
            :state='loadState.orderState' />
          <div class="empty_data" v-if="!orderList.list.length">
            <img src="@/assets/goods/empty_data.png" alt="">
            <p>{{ L['暂无订单～'] }}</p>
          </div>
        </div>
        <!-- 订单列表start -->
      </el-scrollbar>
    </div>

    <div class="right_list_con" v-if="2 == tabIndex" :style="'height:' + (clientHeight - 142) + 'px'">
      <el-scrollbar ref="footbar">
        <!-- 我的足迹start -->
        <div class="foot_print" v-infinite-scroll="load">
          <div class="goods_model" v-for="(footItem, footIndex) in footPrintList.list" :key="footIndex"
            @click="toDetail(footItem, 'goods')">
            <div class="goods_m_img">
              <img :src="footItem.goodsImage" alt="">
            </div>
            <div class="goods_info">
              <div class="goods_info_title">
                {{ footItem.goodsName }}
              </div>
              <div class="goods_info_bottom">
                <span>{{ L['￥'] }}{{ footItem.productPrice }}</span>
                <span @click.stop="sendLink(footItem, 'foot')">{{ L['发送链接'] }} ></span>
              </div>
            </div>
          </div>
          <loadingState v-if="loadState.footState == 'first_loading' || footPrintList.list.length > 0"
            :state="loadState.footState" />
          <div class="empty_data" v-if="!footPrintList.list.length">
            <img src="@/assets/goods/empty_data.png" alt="">
            <p>{{ L['暂无足迹～'] }}</p>
          </div>
        </div>
        <!-- 我的足迹end -->
      </el-scrollbar>
    </div>

    <div class="right_list_con" v-if="3 == tabIndex" :style="'height:' + (clientHeight - 142) + 'px'">
      <el-scrollbar ref="recombar">
        <!-- 店铺推荐start -->
        <div class="store_recom" v-infinite-scroll="load">
          <div v-for="(recomItem, recomIndex) in recomList.list" :key="recomIndex" >
              <div class="goods_model" v-if='recomItem.storeId == storeInfo.storeId'  @click="toDetail(recomItem, 'goods')">
                <div class="goods_m_img">
                  <img :src="recomItem.mainImage" alt="">
                </div>
                <div class="goods_info">
                  <div class="goods_info_title">
                    {{ recomItem.goodsName }}
                  </div>
                  <div class="goods_info_bottom">
                    <span>{{ L['￥'] }}{{ recomItem.goodsPrice }}</span>
                    <span @click.stop="sendLink(recomItem, 'recom')">{{ L['发送链接'] }} ></span>
                  </div>
                </div>
              </div>
            </div>
            <loadingState v-if="loadState.recomState == 'first_loading' || recomList.list.length > 0"
              :state="loadState.recomState" />
            <div class="empty_data" v-if="!recomList.list.length">
              <img src="@/assets/goods/empty_data.png" alt="">
              <p>{{ L['暂无商品～'] }}</p>
            </div>
            
          </div>
        
        <!-- 店铺推荐end -->
      </el-scrollbar>
    </div>
    <div class="right_list_con" v-if="4 == tabIndex" :style="'height:' + (clientHeight - 142) + 'px'">
      <el-scrollbar ref="recombar">
        <!-- 自营店铺推荐start -->
        <div class="store_recom" v-infinite-scroll="load">
          <div v-for="(recomItem, recomIndex) in recomList.list" :key="recomIndex">
            <div class="goods_model" v-if='recomItem.storeId != storeInfo.storeId'  @click="toDetail(recomItem, 'goods')">
              <div class="goods_m_img">
                <img :src="recomItem.mainImage" alt="">
              </div>
              <div class="goods_info">
                <div class="goods_info_title">
                  {{ recomItem.goodsName }}
                </div>
                <div class="goods_info_bottom">
                  <span>{{ L['￥'] }}{{ recomItem.goodsPrice }}</span>
                  <span @click.stop="sendLink(recomItem, 'recom')">{{ L['发送链接'] }} ></span>
                </div>
              </div>
            </div>
          </div>
          <loadingState v-if="loadState.recomState == 'first_loading' || recomList.list.length > 0"
            :state="loadState.recomState" />
          <div class="empty_data" v-if="!recomList.list.length">
            <img src="@/assets/goods/empty_data.png" alt="">
            <p>{{ L['暂无商品～'] }}</p>
          </div>
        </div>
        <!-- 自营店铺推荐end -->
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, getCurrentInstance, watch, computed } from 'vue'
import loadingState from '@/components/loadingState'
import { pcUrl, sourceUrl, getIdentity } from '@/utils/config.js'
import { useStore } from "vuex";
export default {
  name: 'chatRightList',
  components: {
    loadingState
  },
  setup(props, { emit }) {
    const tabIndex = ref(1)
    const changeTab = (index) => {
      tabIndex.value = index
    }
    const { proxy } = getCurrentInstance()
    const orderList = reactive({ list: [] })
    const footPrintList = reactive({ list: [] })
    const recomList = reactive({ list: [] })
    const current = reactive({
      order: 1,
      foot: 1,
      recom: 1
    })
    const hasMore = reactive({
      orderMore: true,
      footMore: true,
      recomMore: true
    })
    const loadState = reactive({
      orderState: '',
      footState: '',
      recomState: ''
    })
    const clientHeight = ref(0)
    const L = proxy.$getCurLanguage()
    const identity = localStorage.getItem('identity')
    const getOrderList = () => {
      proxy.$get(`v3/business/${getIdentity()}/orderInfo/userOrders`, { current: current.order, memberId: CurmemberId.value }).then(res => {
        if (res.state == 200) {
          if (current.order == 1) {
            orderList.list = res.data.list
          } else {
            orderList.list = orderList.list.concat(res.data.list)
          }
          hasMore.orderMore = checkPaginationHasMore(res.data.pagination)
          if (hasMore.orderMore) {
            current.order++
            loadState.orderState = 'allow_loading_more';
          } else {
            loadState.orderState = 'no_more_data';
          }
          orderList.list.map((item) => {
            item.isFold = true
          })
        }
      })
    }

    const getFootList = () => {
      proxy.$get(`v3/member/${getIdentity()}/productLook/userFootprint`, { current: current.foot, memberId: CurmemberId.value }).then(res => {
        if (res.state == 200) {
          if (current.foot == 1) {
            footPrintList.list = res.data.list
          } else {
            footPrintList.list = footPrintList.list.concat(res.data.list)
          }
          hasMore.footMore = checkPaginationHasMore(res.data.pagination)
          if (hasMore.footMore) {
            current.foot++
            loadState.footState = 'allow_loading_more';
          } else {
            loadState.footState = 'no_more_data';
          }
        }
      })
    }

    const getRecomList = () => {
      proxy.$get(`v3/goods/${getIdentity()}/goods/list`, { current: current.recom }).then(res => {
        if (res.state == 200) {
          if (current.recom == 1) {
            recomList.list = res.data.list
          } else {
            recomList.list = recomList.list.concat(res.data.list)
          }
          hasMore.recomMore = checkPaginationHasMore(res.data.pagination)
          if (hasMore.recomMore) {
            current.recom++
            loadState.recomState = 'allow_loading_more';
          } else {
            loadState.recomState = 'no_more_data';
          }

        }
      })
    }

    const CurmemberId = ref(0)
    const getSwitch = (memberId) => {
      CurmemberId.value = memberId
      current.order = 1
      current.foot = 1
      getFootList()
      getOrderList()
    }

    const sendLink = (obj, type) => {
      emit('getObj', obj, type)
    }

    const openMore = (item) => {
      item.isFold = !item.isFold
    }

    const load = () => {
      if (tabIndex.value == 1 && hasMore.orderMore) {
        getOrderList()
      } else if (tabIndex.value == 2 && hasMore.footMore) {
        getFootList()
      } else if (tabIndex.value == 3 && hasMore.recomMore) {
        getRecomList()
      }

    }
    const store = useStore();
    const storeInfo = ref({});
    watch(() => [CurmemberId.value,store.state.storeInfo.isOwnStore], (nv, ov) => {
      if (nv != ov) {
        storeInfo.value = store.state.storeInfo
        if (proxy.$refs.orderbar) {
          proxy.$refs.orderbar.wrap.scrollTop = 0
        }

        if (proxy.$refs.footbar) {
          proxy.$refs.footbar.wrap.scrollTop = 0
        }
      }
    })

    const checkPaginationHasMore = ({ current, pageSize, total }) => {
      return current * pageSize < total * 1;
    }


    const toDetail = (item, type) => {
      switch (type) {
        case 'order': {
          let url
          if (getIdentity() == 'admin') {
            url = `${sourceUrl}manage_order/order_lists_to_detail?orderSn=${item.orderSn}`
          } else {
            url = `${sourceUrl}order/order_detail?orderSn=${item.orderSn}`
          }

          window.open(url, '_blank')
          break;
        }

        case 'goods': {
          let url
          if (pcUrl) {
            url = `${pcUrl}goods/detail?productId=${item.productId}`
          } else {
            if (getIdentity() == 'admin') {
              url = `${sourceUrl}manage_product/goods_detail?id=${item.goodsId}`
            } else {
              url = `${sourceUrl}goods/goods_detail?id=${item.goodsId}`
            }
          }

          window.open(url, '_blank')
          break;
        }
      }
    }

    onMounted(() => {
      clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight
      getRecomList()
    })

    return {
      changeTab,
      tabIndex,
      orderList,
      footPrintList,
      recomList,
      getOrderList,
      getFootList,
      getRecomList,
      sendLink,
      load,
      loadState,
      getSwitch,
      CurmemberId,
      clientHeight,
      L,
      identity,
      getIdentity,
      toDetail,
      openMore,
      store,
      storeInfo
    }

  }
}
</script>

<style lang="scss" scoped>
.sld_chat_right {
  width: 380px;
  margin-left: 14px;
  flex-shrink: 0;

  .right_tab {
    display: flex;
    flex: 1;
    background-color: #f8f8f8;

    div[class^="tab"] {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      padding: 17px 15px;
      cursor: pointer;
      flex: 1;
      text-align: center;
    }

    .on {
      border-top: 2px solid #0e6fd7;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold !important;
      color: #0e6fd7 !important;
    }
  }

  .right_list_con {
    .orderOwn {
      .order_item {
        padding: 20px;
        border-bottom: 1px solid #f8f8f8;

        .sld_hide:nth-child(n + 4) {
          display: none;
        }

        .sld_show {
          display: flex;
        }

        .order_title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
          border-bottom: 1px solid #f8f8f8;

          .order_title_info {
            p:first-child {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              margin-bottom: 5px;
            }

            p:nth-child(2) {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
            }
          }

          .order_state {
            /* width: 50px; */
            padding-left: 5px;
            padding-right: 5px;
            height: 20px;
            background: #eeeeee;
            border-radius: 10px;
            bottom: 0;
            right: 10px;
            text-align: center;
            line-height: 20px;
            color: #666666 !important;
          }
        }

        .order_type {
          margin-top: 17px;
        }

        .openMore {
          margin-top: 20px;
          text-align: center;
          cursor: pointer;

          span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          i {
            margin-left: 10px;
            font-size: 9px;
          }

          &:hover {

            span,
            i {
              color: $colorMain;
            }
          }
        }
      }
    }

    .foot_print {
      padding: 0px 20px;
    }

    .store_recom {
      padding: 0px 20px;
    }
  }

  .goods_model {
    display: flex;
    margin-top: 22px;

    .goods_m_img {
      width: 69px;
      height: 66px;
      border-radius: 6px;
      background: #f5f5f5;
      display: flex;
      justify-content: center;
      overflow: hidden;

      img {
        /* width: 69px; */
        height: 66px;
        /* border-radius: 6px; */
      }
    }

    .goods_info {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 81%;

      .goods_info_title {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .goods_info_bottom {
        display: flex;
        justify-content: space-between;

        span:first-child {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #e2231a;
        }

        span:nth-child(2) {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #e2231a;
          cursor: pointer;
        }
      }
    }
  }
}

.empty_data {
  height: 300px;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80px;
  }

  p {
    margin-left: 10px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #d5d5d5;
  }
}
</style>
